var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"show":_vm.show,"close-on-backdrop":false,"centered":true,"color":"light","size":"xl"},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title text-dark font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cloud.title.devices')))])]},proxy:true},{key:"footer",fn:function(){return [(_vm.capability.user_profile.auth_level<100)?_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.$router.push('/setup/devices')}}},[_vm._v(_vm._s(_vm.$t('cloud.title.move_setup')))]):_vm._e(),_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('button.close')))])]},proxy:true}])},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('Search',{on:{"search":_vm.onSearch}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-storage"}}),_c('strong',[_vm._v(_vm._s(_vm.$t('cloud.title.device')))]),(_vm.count > 0)?_c('span',[_vm._v(" ("+_vm._s(_vm.count)+") ")]):_vm._e()]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.list,"fields":_vm.FIELDS,"items-per-page":10},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"align-middle"},[_c('CBadge',{attrs:{"color":_vm.$utility.getBadgeColor(item.status)}},[_vm._v(_vm._s(_vm.$t(("cloud.title.status_" + (item.status)))))])],1)}},{key:"model",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.model?item.model:"AIBridge Pro")+" ")])}},{key:"num_ch",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.num_ch?item.num_ch:"8")+" ")])}},{key:"memo",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.memo?item.memo:"")+" ")])}},{key:"swver",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.swver?item.swver:"")+" ")])}}])})],1)],1)],1),(_vm.pageTotal > 1)?_c('CRow',{staticClass:"justify-content-end"},[_c('CPagination',{attrs:{"activePage":_vm.pageCurrent,"pages":_vm.pageTotal},on:{"update:activePage":[function($event){_vm.pageCurrent=$event},_vm.onUpdatePage],"update:active-page":function($event){_vm.pageCurrent=$event}}}),_c('CInput',{staticClass:"px-3 w-auto",staticStyle:{"max-width":"120px"},attrs:{"type":"number"},on:{"change":_vm.onChangePageDirectSet},model:{value:(_vm.pageDirectSet),callback:function ($$v) {_vm.pageDirectSet=$$v},expression:"pageDirectSet"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }