<template>
  <CardContainer :title="title" v-if="geoinfo">
    <template slot="view_menu" v-if="regions.length">
      <CDropdown
        v-if="branch_type === 'branch'"
        class="d-inline-block float-right ml-2"
        color="primary"
        :toggler-text="region?region.name:$t('cloud.title.region')"
        size="sm"
        placement="left-start"
      >
        <CDropdownItem @click="onRegion(null)">{{$t('cloud.title.all_region')}}</CDropdownItem>
        <CDropdownItem v-for="r in regions" :key="`region-opts-${r.id}`" @click="onRegion(r)">{{r.name}}</CDropdownItem>
      </CDropdown>
    </template>
    <template slot="chart">
      <Map
        v-if="geoinfo && branches.length"
        :mapHeight="mapHeight"
        :zoom="calcZoom"
        :center="center"
        :locations="calcLocations"
      />
      <CJumbotron v-else>
        <h1 class="display-3">{{$t('cloud.title.no_branch_yet')}}</h1>
        <p class="lead">{{$t('cloud.title.branch_is')}}</p>
        <CButton v-if="myAuthorizationLevel.level <= 20" color="primary" @click="onMove">{{$t('cloud.title.go_to_add')}}</CButton>
      </CJumbotron>
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/StatisticsCard.vue'
import Map from '@/components/map/Map'
import { mapGetters } from 'vuex'

export default {
  name: 'BranchDistribution',

  components:{
    CardContainer,
    Map
  },
  props : {
    title : {type:String, default:''},
    branch_type: {type:String, default:'branch'},
    mapHeight: {type:Number, default: 323}
  },
  data(){
    return {
      regions: [],
      region: null,
      branches: [],
      geoinfo: null,
      center: [0,0]
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    titleCompose() {
      return this.$t('cloud.title.card_branch_distribution');
    },
    calcZoom() {
      // Location 분포에 따라 적당한 zoom level을 계산한다.
      if (!this.geoinfo) return;

      let large = Math.max(this.geoinfo.lat_len, this.geoinfo.lng_len);
      let meter = large * 87000;
      let meter_per_pixel = meter / this.mapHeight;
      if (meter_per_pixel > 4888*8) return 0.7;
      else if (meter_per_pixel > 4888*4) return 1.6;
      else if (meter_per_pixel > 4888*2) return 2.5;
      else if (meter_per_pixel > 4888) return 3.4;
      else if (meter_per_pixel > 2444) return 4.8;
      else if (meter_per_pixel > 1222) return 5.6;
      else if (meter_per_pixel > 610) return 6.4;
      else if (meter_per_pixel > 152) return 7.2;
      else return 8;
      // else if (meter_per_pixel > 76) return 8;
      // else if (meter_per_pixel > 38) return 9;
      // else if (meter_per_pixel > 19) return 10;
      // else if (meter_per_pixel > 10) return 11;
      // else if (meter_per_pixel > 5) return 12;
      // else return 13;
    },
    calcLocations() {
      let locations = this.branches.map(el => {
        return {
          id: el.id,
          name: el.name,
          position: [el.lat, el.lng],
          is_selected: false
        }
      });
      return locations;
    }
  },
  mounted() {
    this.getRegions();
    this.getBranches();
  },
  methods: {
    getBranches() {
      let query_string = '';
      const params = {
        ipp: 50,
        geo_info: 1
      };
      if (this.region) {
        params.region = this.region.name;
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      let url = '/api/branches/';
      axios.get(`${url}${query_string}`)
        .then(result => {
          this.branches = this.$utility.copy(result.data.list);
          if (!result.data.geoinfo) {
            this.geoinfo = {
              lat_center: 37.4843157,
              lng_center: 126.8805164,
              lat_len: 0,
              lng_len: 0
            }
          } else {
            this.geoinfo = this.$utility.copy(result.data.geoinfo);
          }
          this.center = [this.geoinfo.lat_center, this.geoinfo.lng_center];
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getRegions() {
      let query_string = '';
      axios.get(`/api/regions/${query_string}`)
        .then(result => {
          this.regions = JSON.parse(JSON.stringify(result.data.list));
        })
        .catch(() => {
          this.regions = [];
        })
    },
    onRegion(r) {
      this.region = r;
      this.branches = [];
      this.geoinfo = null;
      this.getBranches();
    },
    onMove() {
      this.$router.push('/setup/branches');
    },
    getBranchList() {
      return this.branches;
    }
  }
}
</script>