<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="light"
    size="xl"
  >
    <div
      v-loading="loading"
    >
      <Search @search="onSearch">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-storage" />
              <strong>{{$t('cloud.title.device')}}</strong>
              <span v-if="count > 0"> ({{count}}) </span>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :items="list"
              :fields="FIELDS"
              :items-per-page="10"
            >
              <td slot="status" slot-scope="{item}" class="align-middle">
                <CBadge :color="$utility.getBadgeColor(item.status)">{{$t(`cloud.title.status_${item.status}`)}}</CBadge>
              </td>
              <td slot="model" slot-scope="{item}">
                {{item.model?item.model:"AIBridge Pro"}}
              </td>
              <td slot="num_ch" slot-scope="{item}">
                {{item.num_ch?item.num_ch:"8"}}
              </td>
              <td slot="memo" slot-scope="{item}">
                {{item.memo?item.memo:""}}
              </td>
              <td slot="swver" slot-scope="{item}">
                {{item.swver?item.swver:""}}
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </Search>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
    </div>
    <template #header>
      <h6 class="modal-title text-dark font-weight-bold">{{$t('cloud.title.devices')}}</h6>
    </template>
    <template #footer>
      <CButton v-if="capability.user_profile.auth_level<100" @click="$router.push('/setup/devices')" color="info">{{$t('cloud.title.move_setup')}}</CButton>
      <CButton @click="close" color="secondary">{{$t('button.close')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex'
import Search from '@/containers/Search.vue'
import axios from 'axios'
import qs from 'qs'

export default {
  components: {
    Search
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      show: false,
      loading: false,
      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'status', label: this.$t('cloud.title.status')},
        {key: 'model', label: this.$t('cloud.title.model')},
        {key: 'num_ch', label: this.$t('cloud.title.license_num_ch'), _classes: 'align-middle'},
        {key: 'mac_addr', label: 'MAC'},
        {key: 'swver', label: this.$t('cloud.title.swver')},
        {key: 'memo', label: this.$t('cloud.title.note')},
        {key: 'branch_name', label: this.$t('cloud.title.branch')},
      ],
      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],
    }
  },
  methods: {
    open() {
      this.getList();
      this.show = true;
    },
    close() {
      this.show = false;
    },
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      axios.get(`/api/bridge/${query_string}`)
        .then(result => {
          this.querying = false;
          this.list = this.$utility.copy(result.data.list);
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;

          if (this.initmod) {
            const target = this.list.find(el => el.device_id === this.initmod);
            this.$refs.DeviceForm.open(target);
            this.initmod = null;
          }
        })
        .catch(e => {
          console.error(e);
          this.querying = false;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
  }
}
</script>