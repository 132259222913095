<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="chart">
      <center>
        <div>
          <CChartDoughnut
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'BICounterByType',
  components: {
    CardContainer,
    CChartDoughnut
  },
  data() {
    return {
      title: this.$t('cloud.title.alarms_by_type'),
      stats: []
    }
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    chartData() {
      let count = {
        data: this.stats.map(el => el.count),
        backgroundColor: ['#321fdb','#e55353','#f9b115','#2eb85c','#39f','#4f5d73','#768192','#9da5b1','#c4c9d0','#ebedef']
      };
      // let inc = {
      //   label: 'Increase',
      //   data: this.stats.map(el => el.inc),
      //   borderColor: '#358ec4',
      //   fill: false,
      //   tension: 0.4
      // };
      // let dec = {
      //   label: 'Decrease',
      //   data: this.stats.map(el => el.dec),
      //   borderColor: '#e65054',
      //   fill: false,
      //   tension: 0.4
      // };
      // return [delta, inc, dec];
      return [count];
    },
    chartLabel() {
      let rtn = this.stats.map(el => {
        return this.$t(`cloud.title.eventtype_${el.event_type}`)
      });
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            fontSize: 12
          }
        }
      };
    }
  },
  mounted() {
    this.statCounterTrend();
  },
  methods: {
    statCounterTrend() {
      let query_string = '';
      const params = {};

      let now = new Date();
      let after_day = new Date(now.getTime() + (24*60*60*1000));
      params.t_from = this.$utility.startOfZonedDayStr(now, this.capability.user_profile.timezone, 'yyyyMMdd') + '000000';
      params.t_to = this.$utility.startOfZonedDayStr(after_day, this.capability.user_profile.timezone, 'yyyyMMdd') + '000000';

      query_string = qs.stringify(params);
      axios.get(`/api/alarm/stat/types-count/?${query_string}`)
        .then(result => {
          this.stats = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
