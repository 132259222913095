<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="view_menu">
      <CDropdown
        class="d-inline-block float-right ml-2"
        color="primary"
        :toggler-text="$t(`cloud.title.cycle_${cycle}`)"
        size="sm"
        placement="left-start"
      >
        <CDropdownItem @click="onCycle('hourly')">{{$t('cloud.title.cycle_hourly')}}</CDropdownItem>
        <CDropdownItem @click="onCycle('daily')">{{$t('cloud.title.cycle_daily')}}</CDropdownItem>
      </CDropdown>
    </template>
    <template slot="chart">
      <center>
        <div>
          <CChartBar
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'BICounterByTimeRecent',
  components: {
    CardContainer,
    CChartBar
  },
  data() {
    return {
      title: this.$t('cloud.title.recent_alarm_count'),
      cycle: 'hourly',
      stats: []
    }
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    chartData() {
      let count = {
        data: this.stats.map(el => el.count),
        backgroundColor: '#8a93a2'
      };
      // let inc = {
      //   label: 'Increase',
      //   data: this.stats.map(el => el.inc),
      //   borderColor: '#358ec4',
      //   fill: false,
      //   tension: 0.4
      // };
      // let dec = {
      //   label: 'Decrease',
      //   data: this.stats.map(el => el.dec),
      //   borderColor: '#e65054',
      //   fill: false,
      //   tension: 0.4
      // };
      // return [delta, inc, dec];
      return [count];
    },
    chartLabel() {
      let rtn = this.stats.map(el => {
        const d = new Date(el.created_at);
        if (this.cycle === 'hourly') {
          return this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'HH');
        } else if (this.cycle === 'daily') {
          return this.$utility.GetDateTimeStr('$mm/$dd',d);
        }
      });
      return rtn;
    },
    chartOption() {
      return {
        legend: false,
        scales: {
          xAxes: [{
            stacked: false,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: false
            },
            stacked: false,
            ticks: {
              beginAtZero: true,
              // stepSize: 1
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: 250
              suggestedMax: 10
            },
            gridLines: {
              display: true
            }
          }]
        },
        aspectRatio: this.aspect_ratio
      };
    }
  },
  mounted() {
    this.statAlarmTrend();
  },
  methods: {
    statAlarmTrend() {
      let query_string = '';
      const params = {};
      if (this.cycle === 'hourly') {
        let now = new Date();
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        now = new Date(now.getTime() + 3600000);
        let before_15h = new Date(now.getTime() - (15*60*60*1000));
        params.t_from = this.$utility.zonedDateTimeStr(before_15h, this.capability.user_profile.timezone, 'yyyyMMddHH0000');
        params.t_to = this.$utility.zonedDateTimeStr(now, this.capability.user_profile.timezone, 'yyyyMMddHH0000');
        params.interval = 1 * 3600;
      } else if (this.cycle === 'daily') {
        let now = new Date();
        let after_day = new Date(now.getTime() + (24*60*60*1000));
        let before_15d = new Date(after_day.getTime() - (15*24*60*60*1000));
        params.t_from = this.$utility.startOfZonedDayStr(before_15d, this.capability.user_profile.timezone, 'yyyyMMdd000000');
        params.t_to = this.$utility.startOfZonedDayStr(after_day, this.capability.user_profile.timezone, 'yyyyMMdd000000');
        params.interval = 24 * 3600;
      }
      query_string = qs.stringify(params);
      axios.get(`/api/alarm/stat/recent-count/?${query_string}`)
        .then(result => {
          this.stats = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    },
    onCycle(cycle) {
      this.cycle = cycle;
      this.statAlarmTrend();
    }
  }
}
</script>
