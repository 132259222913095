<template>
  <div>
    <CRow>
      <CCol lg="12" xl="6">
        <BranchDistribution ref="BranchDistribution" :title="$t('cloud.title.card_branch_distribution')" />
      </CCol>
      <CCol lg="6" xl="3">
        <span @click="onCardClick('branch')">
          <CWidgetProgressIcon
            class="card-border"
            :header="`${capability.organization.num_branch}`"
            :text="$t('cloud.title.card_text_branches')"
            color="primary"
            :value="capability.organization.num_branch"
          >
            <CIcon name="cil-building" height="36"/>
          </CWidgetProgressIcon>
        </span>
        <span @click="onCardClick('alarm')">
          <CWidgetProgressIcon
            class="card-border"
            :header="today_event_count"
            :text="$t('cloud.title.card_text_alarms_today')"
            color="danger"
            :value="50"
          >
            <CIcon name="cil-bell" height="36"/>
          </CWidgetProgressIcon>
        </span>
      </CCol>
      <CCol lg="6" xl="3">
        <span @click="onCardClick('device')">
          <CWidgetProgressIcon
            class="card-border"
            :header="`${capability.organization.num_device}`"
            :text="$t('cloud.title.card_text_devices')"
            color="success"
            :value="30"
          >
            <CIcon name="cil-storage" height="36"/>
          </CWidgetProgressIcon>
        </span>
        <span @click="onCardClick('users')">
          <CWidgetProgressIcon
            class="card-border"
            :header="`${capability.organization.num_user}`"
            :text="$t('cloud.title.card_text_users')"
            color="info"
            :value="capability.organization.num_user/10*100"
          >
            <CIcon name="cil-people" height="36"/>
          </CWidgetProgressIcon>
        </span>
      </CCol>
      <CCol lg="12" xl="6">
        <AlarmTypes />
      </CCol>
      <CCol lg="12" xl="6">
        <AlarmRecent />
      </CCol>
    </CRow>

    <BranchViewModal v-if="capability.user_profile" ref="BranchViewModal" />
    <DeviceViewModal v-if="capability.user_profile" ref="DeviceViewModal" />
    <UserViewModal v-if="capability.user_profile" ref="UserViewModal" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import BranchDistribution from './cards/BranchDistribution.vue'
import AlarmTypes from './cards/AlarmTypes.vue'
import AlarmRecent from './cards/AlarmRecent.vue'
import BranchViewModal from './BranchViewModal.vue'
import DeviceViewModal from './DeviceViewModal.vue'
import UserViewModal from './UserViewModal.vue'

export default {
  name: 'Dashboard',

  components: {
    BranchDistribution,
    AlarmTypes,
    AlarmRecent,
    BranchViewModal,
    DeviceViewModal,
    UserViewModal,
  },
  data() {
    return {
      today_event_count: "0"
    }
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getTodayEventCount();
    },
    getTodayEventCount() {
      // let now = new Date();
      // now.setHours(0);
      // now.setMinutes(0);
      // now.setSeconds(0);
      // now.setMilliseconds(0);
      // const now_ts = now.getTime();

      const timeZone = this.capability.user_profile.timezone;
      const now_ts = this.$utility.startOfZonedDay(new Date(), timeZone).getTime();
      
      axios.get(`/api/alarm/stat/count/?sts=${now_ts}`)
        .then(response => {
          this.today_event_count = ""+response.data.event_cnt || "0";
        })
    },
    onCardClick(type) {
      if (type === 'branch') {
        let branches = this.$refs.BranchDistribution.getBranchList();
        if (branches.length <= 0) {
          this.$alert(this.$t('cloud.title.validate.no_branch'), this.$t('cloud.title.confirm_title'), {
            confirmButtonText: this.$t('button.close')
          });
          return;
        }
        this.$refs.BranchViewModal.open(branches[0]);
      } else if (type === 'alarm') {
        this.$confirm(this.$t('cloud.title.confirm_move'), this.$t('cloud.title.confirm_title'), {
          confirmButtonText: this.$t('button.ok'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        }).then(() => {
          this.$router.push('/alarm');
        }).catch(() => {
          // do nothing
        });
      } else if (type === 'device') {
        this.$refs.DeviceViewModal.open();
      } else if (type === 'users') {
        this.$refs.UserViewModal.open();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.card-border {
  border: 2px solid transparent;
  border-radius: 0.25rem;
  cursor:pointer;
}
.card-border:hover {
  border: 2px solid $success;
}
</style>